<template>
    <section>
      <div class="container bg-light rounded-top">
                <div class="row d-flex align-items-center mb-8">
          <!-- text -->
          <div class="form">
            <div class="product-slider">
              <div class="card-body p-4 position-relative">
                      <div class="position-relative pt-2">
                        <div
                          class="d-flex flex-row justify-content-between align-items-start"
                        >
      <div class="w-100 custom-skeleton p-4">
                        <div class="flex mb-3">
                            <Skeleton shape="circle" size="4rem" class="mr-2"></Skeleton>
                            <div>
                                <Skeleton width="10rem" class="mb-2"></Skeleton>
                                <Skeleton width="5rem" class="mb-2"></Skeleton>
                                <Skeleton height=".5rem"></Skeleton>
                            </div>
                        </div>
                       

  <div class="loader-container progress-container">
    <div class="animated-background"></div>
    <div class="grid">
      <div class="grid-left">
        <div class="image">
            <svg x="0px" y="0px" viewBox="0 0 521.1 310.9" style="enable-background:new 0 0 521.1 310.9;" xml:space="preserve">
              
              <g>
                <path class="st0" d="M521,136.6c-0.1-0.6-1.2-13.8-1.9-18.9c-0.3-2.3-0.3-7.7-0.2-12.9c0.1-6.3,0.1-12.3-0.4-15
                                     c-0.7-3.6-3.8-8.3-6.5-12.4c-1-1.5-2-3-2.6-4.1c-1.2-2.2-3-4.3-4.9-6.5c-1.8-2.1-3.7-4.4-5.5-7l-22.9-35l-0.1-0.1
                                     c-0.4-0.5-9.1-11.4-49.7-19.8c-40.1-8.4-152.9-3.9-157.6-3.7c-1.9,0-46.2-0.2-76.7,13.4c-18.6,8.3-41.9,26.1-57.5,38.9
                                     c-7.3,0.1-11.2,2.5-13.5,4c-1.8,1.1-1.7,5.8-1.5,8.8c-1.3,1.2-2.1,1.9-2.4,2.1l-3.5,3.3c-4.5,1.3-27.9,8-30.7,9.1
                                     c-2.1,0.9-7.6,1.4-10.9,1.8c-0.7,0.1-1.4,0.1-1.9,0.2c-4.1,0.4-23.9,5-39.6,17.6c-16.1,12.9-26.4,45.7-27.8,53.8
                                     c-7.4,41,7.3,90.3,10.3,97.6c0.5,1.3,1,2.4,22.3,4.6c2.3,2.6,5.5,5.4,9.9,7.7c-6.8,0.8-42.2,5.2-45.2,12.9
                                     c-3.3,8.5,126.5,39.8,204.5,32.9c69.3-6.1,104.2-14.8,111.1-16.6c1.5-0.1,3-0.2,4.6-0.3c18.5-1.8,29.9-11.9,36.8-23.9l136.4-39.9
                                     c9.4-0.3,15.8-1.8,21.3-16.5c0,0,4.2-11.1,5.2-32.1c0.5-10.9,1.1-29-0.9-43.7L521,136.6z M357.6,233.8
                                     c-3.1,35.6-24.7,43.9-32.7,44.1c-15.8,0.3-27.1-14.8-31-41.6c-0.2-1.7-0.4-3.3-0.5-5c1.6-12.6,4.4-27.5,9.3-41.7
                                     c0.1-0.1,0.2-0.3,0.4-0.5c6.9-10.8,14.6-16.1,22.8-15.9c6.1,0.2,12.5,3.6,17.9,9.7C354.2,194.4,359.3,213.5,357.6,233.8z
                                     M514.1,184.5c-1,31.6-11.9,33-12,33l-0.3,0c-1.6,0-3.6,0-5.6-2c-2.8-3-6-11-4.9-32.4c1.1-22.3,5.6-34.6,12.6-34.6
                                     c0.3,0,1.8,0.1,3.6,2C510.3,153.3,514.8,161.5,514.1,184.5z M504.9,113.1C504.9,113.1,504.8,113.1,504.9,113.1
                                     c-10.5,0.4-18.5,14.4-23.9,41.4c-3.3,16.5-4.6,33.1-4.9,37.9l-105.1,31.3c-0.4-9-2.1-38.4-8.9-54.1c-7.2-20.6-27-20.3-28.4-20.3
                                     c-1.2,0-17.7,0.6-29.3,25.3c-14.1,30.1-16.7,67.6-17.1,82.2c0,0-0.3,0.7-2.6,1.5c-13.6,5-65.7,5.7-142.9,1.9
                                     c-62.8-3.1-118.6-8.1-125.3-10.4c-2-5.9-17.3-53.5-9.9-94.8c0.2-1.1,0.6-2.6,1.1-4.6c10.6,6.7,20,9.9,52.7,13.6
                                     c2.6,14.6,32.8,14.8,49.2,15.3c16.4,0.5,36.8,1.9,42.2-8.8c37.1,0,74-3.4,101.1-11.5c27.3-8.2,31.2-18.6,35.2-39.1
                                     C235.5,134.8,63,146.3,23.9,114c2.8-4.1,5.8-7.7,9.1-10.4c15-12,33.7-16.3,37.5-16.8c0.5-0.1,1.2-0.1,1.9-0.2c3.9-0.4,9.4-0.9,12-2
                                     c2.3-0.9,23-6.9,30.7-9.1l0.5-0.1l4.1-3.9c0.3-0.3,22.9-20.7,46.5-37.1c-5.7,4.1-11.1,8.4-16.1,12.5c-1.6,1.2-2.9,2.3-3.9,3.2
                                     c-0.7,0.6-1.6,1.4-2.7,2.3c-4.4,3.7-8.2,7.1-11.2,9.8c-6.4,5.7-12.3,11-12.3,11s-5.5,4.4-0.6,5.4c10.3,2.2,25.7,1.1,25.7,1.1
                                     s110.2,2.3,127.4,2.1s43.9-2,50.3-2.7c6.4-0.7,14.3-2.3,19.5-12s15.2-27.9,19.9-36.3c4.7-8.4,11.2-18.3,11.2-18.3
                                     S313,8.9,269.5,10.4c-25.5,0.9-43.3,2-53.6,2.8l-9,0.4c28.2-8.4,61.5-8.2,62-8.2c1.2,0,117.1-4.7,156.7,3.6
                                     c37.5,7.8,46.8,17.7,47.3,18.3l22.9,34.9c1.9,2.9,3.9,5.3,5.8,7.4c1.8,2.1,3.4,4,4.5,5.8c0.7,1.2,1.7,2.7,2.8,4.3
                                     c2.4,3.6,5.4,8.1,5.9,10.9c0.4,2.2,0.4,8.6,0.3,14.2c0,5.6-0.1,10.8,0.2,13.4v0c0.1,0.4,0.1,0.9,0.2,1.4
                                     C511.4,113.2,505.3,113.1,504.9,113.1z"/>
                <path class="st0" d="M472.2,28.7c-0.5-0.6-1.3-1.2-2.2-1.8c-3.1-2.6-7.3-3.8-7.3-3.8c-12.3-5.3-32.1-8.5-32.1-8.5
                                     c-9.5-1.5-27-3.1-35.6-1.8s-16,9.2-18.6,13.2c-2.6,4-17.5,33-17.5,33c-4.8,9.2-3.4,26.2-3.4,26.2v2.7l14-0.5l0.5-2.2
                                     c0,0,5.6,0.1,38.8-2.1c4.1-0.3,6-2.5,6.8-3.8l0,0c0.3,0,31-3.6,39.2-4.6c8.7-1.1,12.6-5,14.5-6.9l0.2-0.2
                                     c1.2-1.2,4.5-6.5,10.1-15.8c0.9-1.5,1.8-3,2.2-3.6l0.2-0.2c1.3-2,0.1-5.2-0.7-6.9L472.2,28.7z"/>
              </g>
          </svg>

        </div>
      </div>
      <div class="grid-right">
       <ProgressBar mode="indeterminate"/>
      </div>
    </div>
  </div> <Skeleton width="100%" height="150px"></Skeleton>
                        <div class="flex justify-content-center mt-3">
                            <Skeleton width="4rem" height="2rem"></Skeleton>
                            <Skeleton width="4rem" height="2rem"></Skeleton>
                        </div>
                    </div> </div></div>
                    </div> </div></div>
                    </div> </div>
</section>
</template>
<script>
   export default {
       name: "Skeletons",
       props: ['loading']
   }
</script>
<style lang="scss" scoped>

.p-progressbar {
    border: 0 none;
    height: 0.5rem!important;
    background: #dee2e6;
    border-radius: 3px;
}
.p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: #000066!important;
}

.st0{fill:#EEE;}

$small-break: 640px;
$loader-gradient: (rgba(255, 255, 255, 0.0) 30%, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.0) 80%);

* {
  box-sizing: border-box;
}

body {
  background-color: #F5F5F5;
  padding: 2rem;
}

.loader-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

@keyframes shimmer {
    0% { left: -50% }
    100% { left: 150% }
}

.animated-background {
  -webkit-animation: shimmer 1s infinite ease-out; /* Safari 4+ */
  -moz-animation:    shimmer 1s infinite ease-out; /* Fx 5+ */
  -o-animation:      shimmer 1s infinite ease-out; /* Opera 12+ */
  animation:         shimmer 1s infinite ease-out;
  background: -moz-linear-gradient(to right, $loader-gradient); /* FF3.6-15 */
  background: -webkit-linear-gradient(to right, $loader-gradient); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, $loader-gradient); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.card {
  background-color: white;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0,0,0,0.05);
  border-radius: 16px;
  padding: 1rem;
  width: 100%;
  max-width: 1330px;
  margin: 1rem auto;
}

.loading {
  background-color: #EEE;
}

.image {
  height: 9rem;
  width: 14rem;
  padding: 1.5em;
  
  @media (max-width: $small-break) {
    margin: 0 auto;
  }
}

.title {
  height: 1rem;
  margin-bottom: 1rem;
  width: 50%;  
}

.grid {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  
  @media (max-width: $small-break) {
    display: block;
  }
}

.grid-right {
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.grid-left {
  margin-right: 1rem;
}

.item {
  height: 0.75rem;
  width: 100%;
  border-radius: 10px;
  
  @media (max-width: $small-break) {
    margin-top: 1em;
  }
}

.seller {
  width: 100%;
  height: 2rem;
  margin: 1rem auto 0 auto;
}
</style>