
import { defineComponent } from "vue";

export default defineComponent({
  name: "contenido-out",
  props: {
    title: String,
    button: String,
    url: String,
    text: String,
  },
});
