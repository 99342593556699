
import { ref, defineComponent, onMounted, computed } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Skeletons from "./components/Skeletons.vue";
import SinContenido from "./components/SinContenido.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import moment from "moment";
import { useConfirm } from "primevue/useconfirm";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
interface ReservationDetails {
  reservaId: string;
  segmento: string;
  marca: string;
  modelo: string;
}

export default defineComponent({
  name: "dashboard",
  components: {Skeletons, SinContenido},
  setup() {
    const router = useRouter();
    const store = useStore();
    const flotaActual = ref<ReservationDetails>();
    const visibleBottom = ref(false);
    const confirm = useConfirm();
    onMounted(() => {     
      setCurrentPageTitle("Dashboard");
      obtenerReservasVigentes();
    });
    const currentUser = computed(() => {
      return store.getters.currentUser;
    });
    const reservas = computed(() => {
      return store.getters.allReservas;
    });
    const loading = ref(true);
    const obtenerReservasVigentes = () => {
      store
        .dispatch(Actions.GET_RESERVAS, currentUser.value.driverId)
        .then(() => {
          loading.value = false;
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getCuentaErrors);
        });
    };

    const anular = (reserva, event) => {
        confirm.require({
            target: event.currentTarget,
            message: '¿Está seguro de anular su reserva?',
            icon: 'pi pi-info-circle',
            acceptLabel:"Si",
            rejectLabel:"No",
            acceptClass: 'p-button-danger',
            accept: () => {
                 store
                  .dispatch(Actions.DEL_RESERVA, reserva.reservaId)
                  .then(() => {
                      Swal.fire({
                        text: "Anulación exitosa.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Ok!",
                        customClass: {
                          confirmButton: "btn fw-bold btn-light-primary",
                        },
                      }).then(function () {
                        // Go to page after successfully login
                        obtenerReservasVigentes();
                        //router.push({ name: "dashboard" });
                      });
                    })
                  .catch(() => {
                    const [error] = Object.keys(store.getters.getEmpresaErrors);
                  });
            },
            reject: () => {
                //toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
            }
        });
    };

    const formatDate = (value) => {
      return moment(value).format("MMM D YYYY");
    };
    const formatHour = (value) => {
      return moment(value).format("LT");
    };
    const formatCurrency = (value) => {
      return value.toLocaleString("es-CL", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: "currency",
        currency: "CLP",
      });
    };
    const imagen = (flota)=>{
      if(flota.urlImagen && flota.urlImagen!='')
         return flota.urlImagen;
      return `/media/flota/${flota.segmentoVehiculo}/${flota.marcaVehiculo}/${flota.modeloVehiculo.replace('/','-')}.png`;
    };


    const imageUrlAlt = (event) => {
      event.target.src = "/media/mock/auto3.png";
    };

    const targetReserva = (reserva) => {
      return `#${reserva.reservaId}`;
    };

    const bgBadge = (reserva) => {
      return reserva.codigoEstado > 0 ? "bg-success" : "bg-info";
    };
    const verFlota = (flota) => {
      visibleBottom.value = true;
      flotaActual.value = flota;
    };

    return {
      loading,
      currentUser,
      reservas,
      formatDate,
      formatCurrency,
      formatHour,
      imagen,
      imageUrlAlt,
      targetReserva,
      bgBadge,
      visibleBottom,
      flotaActual,
      verFlota,
      moment,anular
    };
  },
});
